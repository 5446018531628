/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.video-thumb {
  background-color: transparent;
  transition: background-colour 0.5s ease-in-out;
  &:hover,
  &:focus-within {
    background-color: rgba(0, 0, 0, 0.3);

    .play-icon-wrap {
      background-color: transparent;
      transform: scale(1.5);
    }
  }
}

.text-bg-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7343312324929971) 52%,
    rgba(0, 0, 0, 1) 100%
  );
}

.play-icon-wrap {
  background-color: rgba(0, 0, 0, 0.25);
  transition:
    background-colour 0.5s ease-in-out,
    transform 0.5s ease-in-out;
  transform: scale(1);
}

.video-thumb-text {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-width: 100%;
}
