/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.app-seperator {
  height: 1px;
  background: var(--ba-gray-450);
}
